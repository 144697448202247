//import logo from './logo.svg';
import Header from './components/Header/Header';
import About from './components/About/About';
import Gallery from './components/Gallery/Gallery';
import Contact from './components/Contact/Contact';
import Pricing from './components/Pricing/Pricing';
import GMap from './components/Contact/GMap';
import ScrollToTop from "react-scroll-to-top";

import './App.css';

const App = () => {
    return (
      <div className="App">
        <Header />
        <About />
        <Gallery />
        <Pricing />
        <Contact />
        <GMap />
        <ScrollToTop smooth />
    </div>
    );
  }

export default App;
