const Pricing = () => {
    return (
        <section id="pricing">
            <h1>Цени</h1>
            <div className='main-container'>
                {/* <h2>Силен летен сезон - 220 лв.</h2>
                <p>Включва периода от средата на юни, юли, август, до средата на септември</p>

                <h2>Слаб летен 180 лв</h2>
                По 1 месец отпред и отзад */}
                <table>
                    <thead>
                        <tr>
                            <th style={{width: '22%'}}>Сезон</th>
                            <th>Период</th>
                            <th style={{width: '22%'}}>Цена</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Силен летен</td>
                            <td>От средата на юни до средата на септември</td>
                            <td>220 лв.</td>
                        </tr>
                        <tr>
                            <td>Слаб летен</td>
                            <td>До средата на юни и след средата на септември</td>
                            <td>180 лв.</td>
                        </tr>
                        <tr>
                            <td>Силен зимен</td>
                            <td>Декември, януари</td>
                            <td>220 лв.</td>
                        </tr>
                        <tr>
                            <td>Слаб зимен</td>
                            <td>Октомври, ноември, февруари, март</td>
                            <td>180 лв.</td>
                        </tr>
                    </tbody>
                </table>
                <h4>Посочената цена е на вечер за цялата къща.</h4>
                <p>Настаняването е след 14:00 ч., а напускането - до 12:00 ч.</p>
            </div>
        </section>
    );
}

export default Pricing;