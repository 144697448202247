import React from 'react'
import { GoogleMap, LoadScript, MarkerF } from '@react-google-maps/api';

const containerStyle = {
  width: '100%',
  height: '60vh'
};

const center = {
    lat: 43.62811578476096,
    lng: 28.56830405353092
}

const position = {
  lat: 43.62811578476096,
  lng: 28.56830405353092
};

const onLoad = marker => {
    console.log('marker: ', marker)
  }

function GMap() {
  return (
    <LoadScript
      googleMapsApiKey="AIzaSyDaVvYqqg45Kjrvx0TqJpkYU4QD17xSL-M"
    >
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={17}
      >
        { /* Child components, such as markers, info windows, etc. */ }
        <MarkerF position={position} onLoad={onLoad} />
      </GoogleMap>
    </LoadScript>
  )
}

export default React.memo(GMap)