import React from "react";

import * as Scroll from 'react-scroll';
import { Link, Button, Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'

import './Navigation.css';

const Navigation = () => {
    return (
        <>
            <input className="menu-btn" type="checkbox" id="menu-btn" />
            <label className="menu-icon" htmlFor="menu-btn"><span className="navicon"></span></label>
            <ul className="navigation">
                <li>
                    <Link
                        activeClass="active"
                        to="header"
                        spy={true}
                        smooth={true}
                        offset={-70}
                        duration={500}
                    >Начало</Link>
                </li>
                <li>
                    <Link
                        activeClass="active"
                        to="about"
                        spy={true}
                        smooth={true}
                        offset={190}
                        duration={500}
                    >Къщата</Link>
                </li>
                <li>
                    <Link
                        activeClass="active"
                        to="gallery"
                        spy={true}
                        smooth={true}
                        offset={-80}
                        duration={500}
                    >Галерия</Link>
                </li>
                <li>
                    <Link
                        activeClass="active"
                        to="pricing"
                        spy={true}
                        smooth={true}
                        offset={-90}
                        duration={500}
                    >Цени</Link>
                </li>
                <li>
                    <Link
                        activeClass="active"
                        to="contact"
                        spy={true}
                        smooth={true}
                        offset={-90}
                        duration={500}
                    >Контакти</Link>
                </li>
            </ul>
        </>
    );
}

export default Navigation;