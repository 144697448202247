import mobile from './mobile.png';
import viber from './viber.png';

import './Contact.css';

const Contact = () => {
    return (
        <section id="contact">
            <h1>Контакти</h1>
            <div className='main-container'>
                <h2>Адрес:</h2>
                <p>с. Крапец, ул. 2-ра №20</p>
                <h3>Къщата се намира на около:</h3>
                <ul className='location-from'>
                    <li>
                        <h1>250 <span className='dist-measure'>м</span></h1>
                        <div>от морето</div>
                    </li>
                    <li>
                        <h1>21  <span className='dist-measure'>км</span></h1>
                        <div>от границата с Румъния</div>
                    </li>
                    <li>
                        <h1>50  <span className='dist-measure'>км</span></h1>
                        <div>от Албена</div>
                    </li>
                    <li>
                        <h1>75  <span className='dist-measure'>км</span></h1>
                        <div>от най-близкото летище (Варна)</div>
                    </li>
                </ul>
                <h2>За връзка с нас:</h2>
                <p className='contact-phone'><img src={mobile} /><img src={viber} /><span>0889 304 396, 0888 935 652</span></p>
            </div>
        </section>
    );
}

export default Contact;