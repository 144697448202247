import React from "react";
import Navigation from '../Navigation/Navigation.js';

import './Header.css';

const Header = () => {
    return (
        <header id="header" className="header-container">
        <div className="header">
            {/* <div className="nav-bar"> */}
            <a href="#" className="logo">Морска къща <span style={{}}>"Юлиина"</span></a>
            <Navigation />
            </div>
            {/* </div> */}
            <h1 className="tagline">
                ...за едно <span>запомнящо се</span> морско приключение!
            </h1>
        </header>
    );
}

export default Header;