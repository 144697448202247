import img1 from './Images/AN4A0183.jpg';
import img2 from './Images/AN4A0181.jpg';
import img3 from './Images/AN4A0180.jpg';
import img4 from './Images/AN4A0117.jpg';
import img5 from './Images/AN4A0119.jpg';
import img6 from './Images/AN4A0120.jpg';
import img7 from './Images/AN4A0121.jpg';
import img8 from './Images/AN4A0122.jpg';
import img9 from './Images/AN4A0123.jpg';
import img11 from './Images/AN4A0151.jpg';
import img12 from './Images/AN4A0154.jpg';
import img13 from './Images/AN4A0153.jpg';
import img14 from './Images/AN4A0152.jpg';
import img15 from './Images/AN4A0155.jpg';
import img16 from './Images/AN4A0157.jpg';
import img17 from './Images/AN4A0159.jpg';
import img18 from './Images/AN4A0160.jpg';
import img19 from './Images/AN4A0161.jpg';
import img20 from './Images/AN4A0164.jpg';
import img21 from './Images/AN4A0166.jpg';
import img22 from './Images/AN4A0169.jpg';
import img23 from './Images/AN4A0170.jpg';
import img24 from './Images/AN4A0171.jpg';
import img25 from './Images/AN4A0173.jpg';
import img26 from './Images/AN4A0174.jpg';
import img27 from './Images/AN4A0176.jpg';
import img28 from './Images/AN4A0177.jpg';
import img29 from './Images/AN4A0115.jpg';
import img30 from './Images/AN4A0114.jpg';
import img31 from './Images/AN4A0127.jpg';

import { useState } from 'react';
import PhotoAlbum from "react-photo-album";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";

import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/plugins/thumbnails.css";

import './Gallery.css';

const photos = [
    { src: img31, width: 1400, height: 933 },
    { src: img1, width: 1400, height: 933 },
    { src: img2, width: 1400, height: 933 },
    
    { src: img9, width: 1400, height: 933 },
    { src: img13, width: 933, height: 1400 },
    
    { src: img12, width: 1400, height: 933 },
    { src: img8, width: 933, height: 1400 },
    { src: img4, width: 1400, height: 933 },
    { src: img5, width: 933, height: 1400 },
    
    { src: img6, width: 1400, height: 933 },
    { src: img11, width: 933, height: 1400 },
    
    { src: img14, width: 1400, height: 933 },
    { src: img15, width: 1400, height: 933 },
    { src: img16, width: 1400, height: 933 },
    { src: img7, width: 933, height: 1400 },
    { src: img29, width: 1400, height: 933 },
    { src: img17, width: 1400, height: 933 },
    
    { src: img18, width: 1400, height: 933 },
    { src: img19, width: 1400, height: 933 },
    { src: img20, width: 1400, height: 933 },
    { src: img21, width: 1400, height: 933 },
    { src: img22, width: 1400, height: 933 },
    { src: img23, width: 1400, height: 933 },
    { src: img24, width: 1400, height: 933 },
    { src: img26, width: 933, height: 1400 },
    { src: img27, width: 1400, height: 933 },
    { src: img28, width: 1400, height: 933 },
    { src: img25, width: 933, height: 1400 },
    
    { src: img30, width: 1400, height: 933 },
    { src: img3, width: 1400, height: 933 },
];

const slides = photos.map(({ src, width, height }) => ({
    src,
    width,
    height,
}));

const Gallery = () => {
    const [index, setIndex] = useState(-1);

    return (
        <div className='main-container' id="gallery">
        <h1>Галерия</h1>
        <div className='gallery'>
            <PhotoAlbum photos={photos} layout="columns" targetRowHeight={150} onClick={({ index }) => setIndex(index)} />

            <Lightbox
                slides={slides}
                open={index >= 0}
                index={index}
                close={() => setIndex(-1)}
                // enable optional lightbox plugins
                plugins={[Fullscreen, Slideshow, Thumbnails, Zoom]}
            />
        </div>
        </div>
    );
}

export default Gallery;