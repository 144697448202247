import bush from './Icons/bush.png';
import home from './Icons/home.png';
import livingroom from './Icons/living-room.png';
import bathtub from './Icons/bathtub.png';
import fireplace from './Icons/fireplace.png';
import grill from './Icons/grill.png';
import parking from './Icons/parking.png';
import towels from './Icons/towels.png';
import tv from './Icons/tv.png';
import dryer from './Icons/dryer.png';
import shampoo from './Icons/shampoo.png';
import table from './Icons/table.png';
import restaurant from './Icons/restaurant.png';
import wifi from './Icons/wifi.png';
import cyclist from './Icons/cyclist.png';

import './About.css';

const About = () => {
    return (
        <section id="about">
            <div className="highlights">
                <div className="highlight">
                    <img className="h-icon" src={bush} />
                    <p>Къщата разполага с добре оформена зелена градина с рози, подходяща за игри и забавления, външно барбекю и самостоятелен озеленен двор с ограда от камък и ковано желязо.</p>
                </div>
                <div className="highlight">
                    <img className="h-icon" src={home} />
                    <p>Разположена е на тиха и спокойна улица и посреща гости през цялата година. Предлага всички удобства за превъзходна почивка!</p>
                </div>
                <div className="highlight">
                    <img className="h-icon" src={cyclist} />
                    <p>В околностите на Крапец може да се отдадете на любими занимания и активности като пешеходен туризъм, риболов, колоездене, разходка с лодка или гмуркане.</p>
                </div>
            </div>
            <div className='main-container' id="about">
                <h1>За къщата</h1>
                <p>Морска къща "Юлиина" е еднофамилна жилищна сграда, разположена на 90 кв.м едноетажна площ, обградена от 500 кв.м. самостоятелен озеленен двор с външно барбекю.</p>
                <h2>Удобствата, които къщата предлага на своите гости, са:</h2>
                    <ul className='facilities'>
                        <li>
                            <img className="f-icon" src={livingroom} />
                            <div>Голям хол с отлично оборудвана кухня</div>
                        </li>
                        <li>
                            <img className="f-icon" src={restaurant} />
                            <div>Kът за хранене</div>
                        </li>
                        <li>
                            <img className="f-icon" src={tv} />
                            <div>Телевизор със сателитни канали</div>
                        </li>
                        <li>
                            <img className="f-icon" src={bathtub} />
                            <div>Две спални със самостоятелни бани с вана</div>
                        </li>
                        <li>
                            <img className="f-icon" src={shampoo} />
                            <div>Безплатни тоалетни принадлежности</div>
                        </li>
                        <li>
                            <img className="f-icon" src={towels} />
                            <div>Спално бельо и кърпи</div>
                        </li>
                        <li>
                            <img className="f-icon" src={dryer} />
                            <div>Сушилник за дрехи</div>
                        </li>
                        <li>
                            <img className="f-icon" src={fireplace} />
                            <div>Камина на пелети</div>
                        </li>
                        <li>
                            <img className="f-icon" src={parking} />
                            <div>Две паркоместа в двора на къщата</div>
                        </li>
                        <li>
                            <img className="f-icon" src={grill} />
                            <div>Външно барбекю</div>
                        </li>
                        <li>
                            <img className="f-icon" src={table} />
                            <div>Външни мебели</div>
                        </li>
                        <li>
                            <img className="f-icon" src={wifi} />
                            <div>Безплатен интернет достъп</div>
                        </li>
                    </ul>
                <h4>Пушенето вътре в къщата не е разрешено.</h4>
            </div>
        </section>
    );
}

export default About;
